<template>
  <b-row>
    <b-col sm="12">

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Cron</h4>
            <div class="small text-muted">Crear script para tareas programadas</div>
          </b-col>

          <b-col sm="5">
            <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar">
              <i class="fa fa-plus"></i> Agregar
            </b-button>                       
          </b-col>                       
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-table class="mb-0"
                    responsive="sm"
                    head-variant="dark"
                    ref="table"
                    :hover="true"
                    :small="true"
                    :fixed="false"
                    :items="table.items"
                    :fields="table.fields"                    
                    selectable
                    select-mode="single"                    
                    v-if="table.items.length">                       

                <template v-slot:cell(id)="data">
                  <b>{{data.item.id}}</b>
                </template>

                <template v-slot:cell(blocked)="data">
                  <b-icon icon="lock" v-if="data.item.blocked" title="Bloqueado"/>
                </template>

                <template v-slot:cell(name)="data">                       
                  {{data.item.name}}                  
                </template> 
               
                <template v-slot:cell(observations)="data">
                  <div v-if="data.item.observations">
                    <div v-if="data.item.observations.length >50" :title="data.item.observations">
                      <b-icon icon="card-text" class="mr-1" style="font-size:17px;"></b-icon>
                      {{data.item.observations.substr(0, 150)}}
                    </div>
                    <div v-else>                      
                      {{data.item.observations}}
                    </div>
                  </div>
                </template>                

                <template v-slot:cell(cron)="data">                       
                  {{data.item.minute}} {{data.item.hour}} {{data.item.day}} {{data.item.month}} {{data.item.day_week}}             
                </template>        

                <template v-slot:cell(active)="data">
                  <div v-if="data.item.active">
                    <b-badge variant="success">Activo</b-badge>
                  </div>
                  <div v-else>
                    <b-badge variant="danger">Inactivo</b-badge>
                  </div>
                </template>              

                <template v-slot:cell(log_url)="data">                                                           
                  <b-link :href="getLogUrl(data.item)" target="_blank">Ver</b-link>
                </template>                                             

                <template v-slot:cell(f_action)="data">
                  <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                    <b-dropdown-item @click="openCode(data.item)">
                      <b-icon icon="code-slash"></b-icon> Código
                    </b-dropdown-item>    

                    <b-dropdown-header>Log</b-dropdown-header> 
                    <b-dropdown-item @click="openLog(data.item)">
                      <b-icon icon="bar-chart-steps"></b-icon> Ver
                    </b-dropdown-item>  
                    <b-dropdown-item @click="deleteLog(data.item)">
                      <b-icon icon="x"></b-icon> Vaciar
                    </b-dropdown-item>  

                    <b-dropdown-header>Protección</b-dropdown-header> 
                    <b-dropdown-item @click="blockedPHP(data.item)" v-if="!data.item.blocked">
                      <b-icon icon="lock-fill"></b-icon> Bloquear
                    </b-dropdown-item>         
                    <b-dropdown-item @click="unlockedPHP(data.item)" v-else>
                      <b-icon icon="lock-fill"></b-icon> Desbloquear
                    </b-dropdown-item>                        

                    <b-dropdown-header>Acciones</b-dropdown-header> 
                    <b-dropdown-item @click="edit(data.item)">
                      <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                    </b-dropdown-item>
                    <b-dropdown-item @click="remove(data.item)">
                      <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>          
        </b-row>
      </b-card>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
              size="lg"
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-tabs card>
          <b-tab no-body title="General">
            <b-row>
              <b-col md="12">                
                <b-form-group label="Nombre">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.name"
                                required
                                placeholder="Ingresar un nombre">
                  </b-form-input>              
                </b-form-group>      
              </b-col>           

              <b-col md="12">                
                <b-form-group label="Observaciones">
                  <b-form-textarea                                
                    v-model="crud.form.observations"
                    size="sm"
                    placeholder="Ingresar las observaciones"
                  ></b-form-textarea>
                </b-form-group>      
              </b-col>      

              <b-col md="6">
                <b-form-checkbox v-model="crud.form.active" 
                                  switch 
                                  size="sm" 
                                  class="mt-4 pull-left">
                  Activo
                </b-form-checkbox>            
              </b-col>                
            </b-row>
          </b-tab>

          <b-tab no-body title="Cron">
            <b-row>
              <b-col md="6">                
                <b-form-group label="Minuto" description="Minuto en el que la tarea programada será ejecutada. * - [0-59]">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.minute">
                  </b-form-input>              
                </b-form-group>      
              </b-col>    
              <b-col md="6">                
                <b-form-group label="Hora" description="Hora del día en que la tarea será ejecutada. * - [0-23]">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.hour">
                  </b-form-input>              
                </b-form-group>      
              </b-col>
              <b-col md="6">                
                <b-form-group label="Dia" description="Día específico del mes en el que la tarea se ejecutará. * - [1-31]">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.day">
                  </b-form-input>              
                </b-form-group>      
              </b-col>
              <b-col md="6">                
                <b-form-group label="Mes" description="Mes o los meses en los que la tarea será ejecutada. * - [1-12]">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.month">
                  </b-form-input>              
                </b-form-group>      
              </b-col>   
              <b-col md="12">                
                <b-form-group label="Día de la Semana" description="Días de la semana la tarea se ejecutará. * - [0-7]">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.day_week">
                  </b-form-input>              
                </b-form-group>      
              </b-col>                                                            
            </b-row>
          </b-tab>
        </b-tabs>
        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
        </div>
      </b-modal>

    </b-col>
  </b-row>
</template>

<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'   
  import moment from 'moment'
    
  export default {    
    data: () => {
      return {      
        access: {
          module_id: Modules.PHP,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudPHPCron',
          elements: {}
        }, 
        table : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', sortable: true},            
            {key: 'blocked', label: 'Bloqueado', class: 'text-center'},                 
            {key: 'name', label: 'Nombre'},
            {key: 'observations', label: 'Observaciones'},                        
            {key: 'cron', label: 'Cron'},                        
            {key: 'active', label: 'Activo'},       
            {key: 'log_url', label: 'LOG'}, 
            {key: 'f_action', label:''},
          ],
        },
        crud: {
          form: {
            id: 0,
            name: '',
            type: '',
            observations: '',            
            minute: '*',
            hour: '*',
            day: '*',
            month: '*',
            day_week: '*',
            active: false,
          },          
        },
        modal: {
          form: {
            active: false,
            title: ''
          },
        },
        constant: {
          type: 'cron'
        },        
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {
      this.load()
    },
    methods: {
      getRowCount (items) {
        return items.length
      },     
      load() {
        var result = serviceAPI.obtenerPHP(this.constant.type)

        result.then((response) => {
          var data = response.data
          this.table.items = data   
          
          this.table.items.forEach(element => {                        
            if(!element.blocked) {
              element._rowVariant = 'danger'
            }            
          });   
          
          if(this.$refs.table) {            
            this.$refs.table.$forceUpdate()
          }             
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });        
      },
      getLogUrl(item) {
        return item.log_url + Session.getToken()         
      },      
      add() {
        this.crud.form.id = 0
        this.crud.form.name = ''
        this.crud.form.type = this.constant.type        
        this.crud.form.observations = ''
        this.crud.form.minute = '*'
        this.crud.form.hour = '*'
        this.crud.form.day = '*'
        this.crud.form.month = '*'
        this.crud.form.day_week = '*'
        this.crud.form.active = false

        this.modal.form.title = "Nuevo Cron"
        this.modal.form.active = true
      },
      edit(item) {
        this.crud.form.id = item.id        
        this.crud.form.name = item.name
        this.crud.form.type = item.type        
        this.crud.form.observations = item.observations
        this.crud.form.minute = item.minute
        this.crud.form.hour = item.hour
        this.crud.form.day = item.day
        this.crud.form.month = item.month
        this.crud.form.day_week = item.day_week
        this.crud.form.active = item.active

        this.modal.form.title = "Editar Cron"
        this.modal.form.active = true
      },
      remove(item) {
        this.crud.form.id = item.id        

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item (' + this.crud.form.id + ') con fecha ' + this.crud.form.name + '?', {
          title: 'Borrar Registro',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarPHP(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.load()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      save() {
        let loader = this.$loading.show();
        
        var result = null
        if (this.crud.form.id) {
          result = serviceAPI.editarPHP(this.crud.form);
        } else {
          result = serviceAPI.agregarPHP(this.crud.form);
        }

        result.then((response) => {
          this.modal.form.active = false
          loader.hide()
          this.load()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      openCode(item) {
        this.$router.push({ name: 'PHPStaffCrudPHPCode', params: {id: item.id}  })
      },

      blockedPHP(item) {
        this.$bvModal.msgBoxConfirm('¿Desea bloquear el bloque de código (' + item.name + ')?', {
          title: 'Bloquear código',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'secondary',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'secondary',
          headerTextVariant: 'dark',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {         
            let loader = this.$loading.show();

            var params = {
              id: item.id,
              blocked: true
            }
            
            var result = serviceAPI.guardarBlockedPHP(params);
                    
            result.then((response) => {                    
              loader.hide()
              this.load()
              this.$awn.success("Código bloqueado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            }) 
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })                  
      },
      unlockedPHP(item) {
        this.$bvModal.msgBoxConfirm('¿Desea desbloquear el bloque de código (' + item.name + ')?', {
          title: 'Desbloquear código',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'secondary',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'secondary',
          headerTextVariant: 'dark',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {              
            let loader = this.$loading.show();

            var params = {
              id: item.id,
              blocked: false
            }
            
            var result = serviceAPI.guardarBlockedPHP(params);
                    
            result.then((response) => {                    
              loader.hide()
              this.load()
              this.$awn.success("Código desbloqueado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })                    
      },    
      
        
      openLog(item) {
        const url = this.getLogUrl(item);
        window.open(url, '_blank');        
      },
      deleteLog(item) {        
        var result = serviceAPI.eliminarPHPLog(item.id);

        result.then((response) => {                          
          this.$awn.success(response.data);
        })
        .catch(error => {               
          this.$awn.alert(Error.showError(error));
        })        
      },        
    }      
  }
</script>
<style>

</style>